.publication-conditions p {
    text-indent: 2rem;
    text-align: justify;
    margin-bottom: 1rem;
}

.publication-conditions h1 {
    text-indent: 2rem;
    margin-bottom: 2rem;
}

.publication-conditions h2 {
    text-indent: 2rem;
    margin-bottom: 1rem;
}

.publication-conditions h3 {
    text-indent: 2rem;
    margin-bottom: 1rem;
}

.publication-conditions .logo {
    height: 6vh;
}