.exitButton {
    background: linear-gradient(180deg, #00EC93 -225.4%, #0038CD 97.84%) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25) !important;
    text-transform: none !important;
    border-radius: 2em !important;
    color: white !important;
    font-weight: bold !important;
    padding: .5em 2em .5em 2em !important;
}

.exitButton:disabled {
    background: linear-gradient(180deg, #535353 -225.4%, #6a6a6a 97.84%) !important;
}
