.terms-of-use p {
    text-indent: 2rem;
    text-align: justify;
    margin-bottom: 1rem;
}

.terms-of-use h1 {
    text-indent: 2rem;
    margin-bottom: 2rem;
}

.terms-of-use h2 {
    text-indent: 2rem;
    margin-bottom: 1rem;
}

.terms-of-use h3 {
    text-indent: 2rem;
    margin-bottom: 1rem;
}


.terms-of-use .logo {
    height: 6vh;
}