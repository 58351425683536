.merchButton {
    color: black !important;
    text-transform: none !important;
    border-radius: 4px !important;
    border: 1px solid rgba(165, 175, 185, 0.30) !important;
    background: #FFF !important;
}

.merchButtonSelected {
    color: black !important;
    text-transform: none !important;
    border-radius: 4px !important;
    border: 1px solid #A5AFB9 !important;
    background: #ECF0F4 !important;
}

.calcButton {
    background: linear-gradient(180deg, #00EC93 -225.4%, #0038CD 97.84%) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25) !important;
    text-transform: none !important;
    border-radius: 2em !important;
    color: white !important;
    font-weight: bold !important;
    padding: .5em 2em .5em 2em !important;
}

.secondaryButton {
    text-transform: none !important;
    border-radius: 2em !important;
    font-weight: bold !important;
    padding: .5em 2em .5em 2em !important;
}

.calcButton:disabled {
    background: grey !important;
}

.resultsTitle {
    color: black;
    text-transform: uppercase;
    font-size: 1em;
    margin-top: 1em;
}

.resultsSubTitle {
    color: #A5AFB9;
    font-size: 0.7em;
}

.resultsValue {
    color: black;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 500;
}

.resultsSubValue {
    color: black;
    font-size: 1em;
    text-transform: none;
    font-weight: bold;
}
